import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

//import 'src/assets/js/owl-carousel/owl.carousel.js'
//require('owl.carousel');


$(document).foundation();

$(document).ready(function() {

	//$("#t2g-carousel").owlCarousel();
//	$("#t2g-carousel").owlCarousel({

//		navigation : false, // Show next and prev buttons
//		slideSpeed : 300,
//		paginationSpeed : 400,
//		singleItem:true,
//		autoPlay: 7000

		// "singleItem:true" is a shortcut for:
		// items : 1,
		// itemsDesktop : false,
		// itemsDesktopSmall : false,
		// itemsTablet: false,
		// itemsMobile : false

//	});

	var time = 7; // time in seconds

	var $progressBar,
		$bar,
		$elem,
		isPause,
		tick,
		percentTime,
		number_of_slides,
		slide_number;

		slide_number = 0;
		number_of_slides = 4;

	//Init the carousel
	$("#t2g-carousel").owlCarousel({
		slideSpeed : 500,
		paginationSpeed : 500,
		singleItem : true,
		afterInit : progressBar,
		afterMove : moved,
		startDragging : pauseOnDragging
	});

	//Init progressBar where elem is $("#t2g-carousel")
	function progressBar(elem){
		$elem = elem;
		//build progress bar elements
		buildProgressBar();
		//start counting
		start();
	}

	//create div#progressBar and div#bar then append to $("#t2g-carousel")
	function buildProgressBar(){
		$progressBar = $("<div>",{
			id:"progressBar"
		});
		$bar = $("<div>",{
			id:"bar"
		});
		$progressBar.append($bar).appendTo($elem);
	}

	function start() {
		//reset timer
		percentTime = 0;
		isPause = false;
		//run interval every 0.01 second
		tick = setInterval(interval, 10);

		var class_to_remove = 'slide-' + slide_number;
		$('#progressBar #bar').removeClass(class_to_remove);
		if (slide_number % number_of_slides === 0) {
			slide_number = 0;
		}
		slide_number++;
		var class_to_add = 'slide-' + slide_number;
		$('#progressBar #bar').addClass(class_to_add);
	};

	function interval() {
		if(isPause === false){
			percentTime += 1 / time;
			$bar.css({
				width: percentTime+"%"
			});
			//if percentTime is equal or greater than 100
			if(percentTime >= 100){
				//slide to next item
				$elem.trigger('owl.next')
			}
		}
	}

	//pause while dragging
	function pauseOnDragging(){
		isPause = true;
	}

	//moved callback
	function moved(){
		//clear interval
		clearTimeout(tick);
		//start again
		start();
	}

	//uncomment this to make pause on mouseover
	// $elem.on('mouseover',function(){
	//   isPause = true;
	// })
	// $elem.on('mouseout',function(){
	//   isPause = false;
	// })

	//Init the carousel
	$("#participating-groups").owlCarousel({
		autoPlay: 7000,
		slideSpeed : 500,
		paginationSpeed : 500,
		singleItem : true,
		transitionStyle : "fade",
		navigation: false,
		pagination: false
	});

	$("#participating-groups-small").owlCarousel({
		autoPlay: 7000,
		slideSpeed : 500,
		paginationSpeed : 500,
		singleItem : true,
		transitionStyle : "fade",
		navigation: false,
		pagination: false
	});

	$( ".open" ).click(function(event) {
		event.preventDefault();
		$(this).children(".icon").toggleClass('icon-plus icon-minus');
		$(this).next('.slidedown').slideToggle();
	});

	$( ".small-search a" ).click(function(event) {
		event.preventDefault();
		$(".small-search-input").fadeToggle();
	});

	$( ".small-search-input .icon-cancel" ).click(function(event) {
		event.preventDefault();
		$(".small-search-input").fadeToggle();
	});

	// $('img').on('load', function () {
	// 	$(document).foundation('equalizer', 'reflow');
	// });

	if ($('body.page').length || $('body.subpage').length) {
		var site_section = $('body').data('section');
		var menu_text = '';
		$('.dropdown.menu.show-for-large > li > a').each(function(index) {
			menu_text = $(this).html();
			if (site_section.toLowerCase() === menu_text.toLowerCase()) {
				$(this).parent().addClass('active-section');
			}
		});
	}

	$('.radio-wrap').click(function() {

		$('.radio-wrap').removeClass('checked');
		$(this).addClass('checked');

		//show/hide form sections depending on radio button clicked
		$('.org-wrap').addClass('org-margin');
		if ($(this).attr('value') == "ind") {
			$('.ind-wrap').fadeIn();
			$('.org-wrap').hide();
			$("#ind").prop("checked", true);
			$('#email-label').show();
			$('#email').show();
		} else if ($(this).attr('value') == "org") {
			$('.org-wrap').removeClass('org-margin');
			$('.org-wrap').fadeIn();
			$('.ind-wrap').hide();
			$("#org").prop("checked", true);
			$('#org-person-name-label').show();
			$('#org_person_name').show();
		} else if ($(this).attr('value') == "both") {
			$('.org-wrap').fadeIn();
			$('.ind-wrap').fadeIn();
			$("#both").prop("checked", true);
			$('#email-label').hide();
			$('#email').hide();
			$('#org-person-name-label').hide();
			$('#org_person_name').hide();
		}

		$('.form-end').show();
	});

	$('#pledge-form .checkbox-wrap').click(function() {
		if ($(this).hasClass("checked")) {
			$(this).children('input').removeAttr("checked");
			$(this).toggleClass('checked unchecked');
		} else {
			$(this).children('input').attr("checked", "checked");
			$(this).toggleClass('checked unchecked');
		}

	});

	function submitPledge(data){
		$.ajax({
			type: "GET",
			url: "http://forms.degrayhsdirectory.com/pledge",
			data: $('#pledge-form').serialize(),
			contentType: "application/x-www-form-urlencoded; charset=UTF-8",
			crossDomain: true,
			dataType: "jsonp",
			cache: false,
			processData: false,
			success: function (returndata, status, jqXHR) {
				console.log(jqXHR);
			},
			error: function (jqXHR, status, errorThrown) {
				// error handler
				//console.log(jqXHR);
				//alert('fail' + status.code);
			}
		});
	}

	function processPledge() {
		var form_data = '{';
		var form_array = new Array();

		var pledge_type = $('input[name=pledge-type]:checked', '#pledge-form').val();
		var name = $("#name").val();
		var email = '';
		var other_text = $("#other-text").val();
		var org_other_text = $("#org_other_text").val();
		var org_name = $("#org_name").val();

		if (pledge_type === 'ind') {
			email = $("#email").val();
		} else {
			email = $("#org_email").val();
		}

		var chat = 'no';
		var move = 'no';
		var veggies = 'no';
		var share = 'no';
		var diabetes = 'no';
		var ind_other = 'no';
		var org_join = 'no';
		var org_share = 'no';
		var org_diabetes = 'no';
		var org_make = 'no';
		var org_request = 'no';
		var org_other = 'no';

		if ($("#checkbox-wrap-chat").hasClass('checked')) { chat = 'yes'}
		if ($("#checkbox-wrap-move").hasClass('checked')) { move = 'yes'}
		if ($("#checkbox-wrap-veggies").hasClass('checked')) { veggies = 'yes'}
		if ($("#checkbox-wrap-share").hasClass('checked')) { share = 'yes'}
		if ($("#checkbox-wrap-diabetes").hasClass('checked')) { diabetes = 'yes'}
		if ($("#checkbox-wrap-other").hasClass('checked')) { ind_other = 'yes'}

		if ($("#checkbox-wrap-org-join").hasClass('checked')) { org_join = 'yes'}
		if ($("#checkbox-wrap-org-share").hasClass('checked')) { org_share = 'yes'}
		if ($("#checkbox-wrap-org-diabetes").hasClass('checked')) { org_diabetes = 'yes'}
		if ($("#checkbox-wrap-org-make").hasClass('checked')) { org_make = 'yes'}
		if ($("#checkbox-wrap-org-request").hasClass('checked')) { org_request = 'yes'}
		if ($("#checkbox-wrap-org-other").hasClass('checked')) { org_other = 'yes'}


		form_data += '"pledge_type":' + '"' + pledge_type + '"';
		form_data += ',"name":' + '"' + name + '"';
		form_data += ',"email":' + '"' +  email + '"';
		form_data += ',"org_name":' + '"' +  org_name + '"';
		form_data += ',"other_text":' + '"' +  other_text + '"';
		form_data += ',"ind_other":' + '"' +  ind_other + '"';
		form_data += ',"chat":' + '"' + chat + '"';
		form_data += ',"move":' + '"' + move + '"';
		form_data += ',"veggies":' + '"' + veggies + '"';
		form_data += ',"share":' + '"' + share + '"';
		form_data += ',"diabetes":' + '"' + diabetes + '"';
		form_data += ',"org_other_text":' + '"' +  org_other_text + '"';
		form_data += ',"org_join":' + '"' + org_join + '"';
		form_data += ',"org_share":' + '"' + org_share + '"';
		form_data += ',"org_diabetes":' + '"' + org_diabetes + '"';
		form_data += ',"org_make":' + '"' + org_make + '"';
		form_data += ',"org_request":' + '"' + org_request + '"';
		form_data += ',"org_other":' + '"' + org_other + '"';
		form_data += "}";

		submitPledge(form_data);

		$(".form-success").fadeToggle('');
		$('#pledge-form').toggle();
	};

	$("#pledge-form").on("submit", function(ev) {
		ev.preventDefault();
		//console.log("Submit for form id "+ev.target.id+" intercepted");
	});

	$("#pledge-form").on("formvalid.zf.abide", function(ev,frm) {
		//console.log("Form id "+frm.attr('id')+" is valid");
		processPledge();
	})

	Foundation.Abide.defaults.validators['required_if_org'] =
		function($el,required,parent) {
			var pass_test = true;
			if ($el.is(':visible')) {
				if ($el.val() != '') {
					pass_test = true;
				} else {
					pass_test = false;
				}
			}
			return (pass_test);
		};

	Foundation.Abide.defaults.validators['required_if_ind'] =
		function($el,required,parent) {
			var pass_test = true;
			if ($el.is(':visible')) {
				if ($el.val() != '') {
					pass_test = true;
				} else {
					pass_test = false;
				}
			}
			return (pass_test);
		};

	Foundation.Abide.defaults.validators['sbe'] =
		function($el,required,parent) {
			var pass_test = true;
			if ($el.val() != '') {
				pass_test = false;
			} else {
				pass_test = true;
			}
			return (pass_test);
		};


	Foundation.Abide.defaults.validators['required_if_ind_other'] =
		function($el,required,parent) {
			var pass_test = true;
			if ($el.is(':visible')) {
				if ($("#other").is(':checked')) {
					if ($el.val() != '') {
						pass_test = true;
					} else {
						pass_test = false;
					}
				}
			}
			return (pass_test);
		};

	Foundation.Abide.defaults.validators['required_if_org_other'] =
		function($el,required,parent) {
			var pass_test = true;
			if ($el.is(':visible')) {
				if ($("#org_other").is(':checked')) {
					if ($el.val() != '') {
						pass_test = true;
					} else {
						pass_test = false;
					}
				}
			}
			return (pass_test);
		};

	Foundation.Abide.defaults.validators['checkbox_min'] =
		function($el,required,parent) {
			var element_id = $el.attr('id');
			element_id = element_id.replace("_", "-");
			if ($('#checkbox-wrap-' + element_id).is(':visible')) {
				var group = parent.closest('.checkbox-group');
				var min = group.attr('data-validator-min');
				var checked = group.find(':checked').length;
				if (checked >= min) {
					//group.find('small.error').hide();
					$("#checkbox-message").hide();
					return true;
				} else {
					//group.find('small.error').css({
					//	display: 'block'
					//});
					$("#checkbox-message").show();
					return false;
				}
			} else {
				return true;
			}
		};

	$( ".icon-toggle" ).click(function(event) {
		event.preventDefault();
		$(this).parent().parent().next(".hidden-checkbox-text").slideToggle();
		$(this).toggleClass("icon-plus icon-minus");
	});

	$( ".campaign-planks-wrapper .plank-icon" ).each(function( index ) {

		var icon = this;

		var open = setTimeout(function() {
			$(icon).addClass("expand");
		}, 500 * index);

		var close = setTimeout(function() {
			$(icon).removeClass("expand");
		}, (500 * index) + 500);

	});

	$( ".plank-carousel-text .carousel-column.empower-column .plank-icon" ).each(function( index ) {

		var icon = this;

		var open = setTimeout(function() {
			$(icon).addClass("expand");
		}, 500 * index);

		var close = setTimeout(function() {
			$(icon).removeClass("expand");
		}, (500 * index) + 500);

	});

	$( ".plank-carousel-text .carousel-column.improve-column .plank-icon" ).each(function( index ) {

		var icon = this;

		var open = setTimeout(function() {
			$(icon).addClass("expand");
		}, 500 * index);

		var close = setTimeout(function() {
			$(icon).removeClass("expand");
		}, (500 * index) + 500);

	});

	$( ".plank-carousel-text .carousel-column.leverage-column .plank-icon" ).each(function( index ) {

		var icon = this;

		var open = setTimeout(function() {
			$(icon).addClass("expand");
		}, 500 * index);

		var close = setTimeout(function() {
			$(icon).removeClass("expand");
		}, (500 * index) + 500);

	});

	$(".plank-carousel-text").click(function() {
		window.location = "/Resources/planks_resources.html";
	});

});

$(window).resize(function () {
	// Use modernizer or some other to to test for flexbox
	if (!$("html").hasClass("flexbox")) {
		if ($(window).outerWidth() > 640) {
			var w = Math.floor(100 / $(".top-bar > ul > li").length) + "%"
			// console.log(w);
			$(".top-bar > ul > li").css({
				"width": w,
				"float": "left"
			});
		} else {
			$(".top-bar > ul > li").removeAttr('style');
		}
	}
});
$(window).resize();


